/* You can add global styles to this file, and also import other style files */

@import "src/styles/fd-theme.scss";
@import "src/styles/variables.scss";

* {
  font-family: Montserrat, sans-serif !important;
}

html {
  height: 100%;
}
body {
  margin: 0;
  position: relative;
}

.container {
  background-color: #f4f4f4;
}

.fd-accreditation-form {
  &__container {
    width: 90%;
    max-width: 1420px;
    margin: 40px auto;
    background-color: $fd-white;
    position: relative;

  }
}

@media(max-width: 1990px) {
  .fd-accreditation-form {
    &__container {
      
      width: 85%;
    }
  }
}

@media(max-width: 1280px) {
  .fd-accreditation-form {
    &__container {
      width: 80%;
    }
  }
}

@media(max-width: 950px) {
  .fd-accreditation-form {
    &__container {
      width: 60%;
    }
  }
}

//FirstData styles

.fd-subtitle {
  color: $fd-indigo-blue;
  text-align: center;
}

.fd-subtitle-left {
  color: $fd-indigo-blue;
  text-align: left;
}

.fd-label {
  &__container {
    width: 100%;
    margin-top: 10px;

    & .mat-form-field-wrapper .mat-form-field-underline {
      background-color: $fd-light-gray;
    }
  }
}

.fd-search-bar {
  &__container {
    & .mat-form-field-wrapper .mat-form-field-underline {
      background-color: $fd-alternate-blue;
      opacity: 0.38;
    }

    & .mat-form-field-label-wrapper .mat-form-field-label {
      color: $fd-alternate-blue;
      opacity: 0.38;
    }
  }
}

//Custom Angular Material styles

.fd-input__icon-container {
  margin-left: -50px;

  & > img {
    width: 45px;
  }
}

.header.mat-toolbar.mat-primary.mat-toolbar-single-row {
  padding: 0;
}

.mat-form-field-disabled.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba($fd-dark-gray, 0.35);
}

.fd-input__container.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline,
.fd-currency-input__container.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline,
.fd-value-editor__container.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-color: rgba($fd-dark-gray, 0.35);
  background-image: none;
}

.mat-form-field .mat-form-field-required-marker,
.mat-form-field.mat-focused .mat-form-field-required-marker {
  color: $fd-indigo-blue;
}

.mat-form-field .mat-select-arrow {
  position: relative;
  margin: 0 auto;
  border: none;
  height: 4px;
  width: 34px;
}

.mat-form-field .mat-select-arrow:before,
.mat-form-field .mat-select-arrow:after {
  content: "";
  position: absolute;
  width: 9px;
  border: 2px solid $fd-indigo-blue;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.mat-form-field .mat-select-arrow:before {
  transform: rotateZ(45deg);
  -webkit-transform: rotateZ(45deg);
  left: 15px;
}
.mat-form-field .mat-select-arrow:after {
  transform: rotateZ(-45deg);
  -webkit-transform: rotateZ(-45deg);
  left: 22px;
}

.mat-radio-button .mat-radio-outer-circle {
  border-width: 1px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  background-color: $fd-indigo-blue;
}

.mat-radio-button .mat-radio-inner-circle,
.mat-radio-button .mat-radio-outer-circle,
.mat-radio-button .mat-radio-container {
  border-width: 1px;
  height: 30px;
  width: 30px;
}

.mat-radio-button .mat-radio-label-content {
  font-size: 18px;
  color: $fd-dark-gray;
}

.fd-spinner .overlay {
  position: fixed !important;
}

// Styles modal
.fd-modal {
  &__container {
    text-align: center;
    padding: 10px 45px;

    .mat-expansion-panel-content{
      height: 140px;
      overflow-y: scroll;
    }
  }

  &__warning-icon {
    color: $fd-yellow;
  }

  &__title {
    text-transform: uppercase;
    color: $fd-dark-blue;
    font-weight: 700;
  }
  &__close-button {
    margin: auto;
  }

  &__details{
    word-break: break-word;
    white-space: break-spaces;

    &-container{
      text-align: left;
    }
  }

}

.fd-checkbox {
  &__container {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mat-checkbox-inner-container {
    height: 24px;
    width: 24px;
  }
  .mat-checkbox-frame {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
  }
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $fd-indigo-blue;
  }
}

.fd-option-picker {
  &__container .mat-select-placeholder {
    color: $fd-dark-gray;
  }
}

.fee-edit {
  text-decoration: underline;
  color: $fd-indigo-blue;
  cursor: pointer;
}

[data-class="fee-edit-pencil"] {
  color: $fd-indigo-blue;
  cursor: pointer;
  margin-left: 10px;
}

mat-tooltip-component .fd-input__tooltip-dialog {
  font-size: 15px;
  background-color: $fd-white;
  color: $fd-indigo-blue;
  box-shadow: -6px -7px 0px -7px rgba(0, 0, 0, 0.2), 2px 2px 10px 0px rgba(0, 0, 0, 0.14), -2px 6px 20px 0px rgba(0, 0, 0, 0.12)
}

/* Expansion panel */

.fd-proposal-detail {
  &__expansion-panel {
    .mat-expansion-indicator:after {
      color: $fd-white;
      font-weight: bold;
      margin-top: -5px;
      border-width: 0 3px 3px 0;
    }
    .mat-expansion-indicator {
      padding: 5px 10px;
      border-radius: 50%;
      background-color: $fd-dark-blue;
    }

    &.mat-expansion-panel:not([class*="mat-elevation-z"]) {
      border-bottom: 2px solid $fd-dark-blue;
    }

    .mat-expansion-panel-header-title {
      color: $fd-indigo-blue;
    }
  }
}

.fd-text {
  color: $fd-dark-gray;
}

.custom-dialog {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  mat-dialog-container {
    width: 95%;
    padding: 20px;
    overflow: auto;
    border-radius: 0;
  }
}
